import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { ComponentSize } from '@omgui/component-size';

const REACTIVE_LABEL_WIDTH_CLASSES = 'mt-5 col-9 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 text-center';

@Component({
  selector: 'om-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
})
export class SubmitButton implements OnInit {
  @Input() submitting: boolean;
  @Input() label: string;
  @Input() buttonClass = '';
  @Input() disabled = false;
  @Input() ariaDisabled = false;
  /** Controls the placement of the spinner during submission. If false: hides the button and shows the spinner */
  @Input() showSpinnerInsideButton = false;
  @Input() @HostBinding('class') class: string = REACTIVE_LABEL_WIDTH_CLASSES;

  readonly componentSize = ComponentSize;

  ngOnInit(): void {
    // if a11y-compatible disabled state marker is passed, ignore brute force
    // attribute (so screen readers wouldn't ignore the button)
    if (this.ariaDisabled) {
      this.disabled = false;
    }
  }
}
