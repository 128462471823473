import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppointmentBookingStateService } from '@app/appointment/appointment-booking-state-service';
import { AppointmentInventory } from '@app/appointment/provider-inventories';
import { SwitchToRemoteGraphQLService } from '@app/appointment/switch-to-remote-graphql.service';

import {
  AbstractConfirmationModalActionService,
  SubmissionResults,
} from '../base-confirmation-modal-action/abstract-confirmation-modal-action.service';

@Injectable({
  providedIn: 'root',
})
export class SwitchToRemoteConfirmationModalActionService implements AbstractConfirmationModalActionService {
  constructor(
    private bookingStateService: AppointmentBookingStateService,
    private switchToRemoteAppointmentGraphQLService: SwitchToRemoteGraphQLService,
  ) {}

  getConfirmationCTA(isAppointmentConfirmationFullPageEnabled = false): string {
    if (isAppointmentConfirmationFullPageEnabled) {
      return 'Book Remote Visit';
    }

    return 'Confirm Appointment';
  }

  getConfirmingCTA(isAppointmentConfirmationFullPageEnabled = false): string {
    if (isAppointmentConfirmationFullPageEnabled) {
      return 'Booking your Remote Visit...';
    }

    return 'Submitting...';
  }

  executeAppointmentAction$(
    selectedInventory: AppointmentInventory, // eslint-disable-line @typescript-eslint/no-unused-vars
  ): Observable<SubmissionResults> {
    const bookingState = this.bookingStateService.getAppointmentBookingState();
    const { fromAppointment } = this.bookingStateService.getAppointmentBookingState();

    return this.switchToRemoteAppointmentGraphQLService
      .mutate({
        input: {
          id: fromAppointment.id.toString(),
          sendSms: bookingState.sendSms,
          smsNumber: bookingState.sendSms ? bookingState.phoneNumber : null,
        },
      })
      .pipe(map(result => result.data.switchToRemoteAppointment));
  }
}
