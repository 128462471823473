import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AppointmentRemoteLinkGraphqlService } from '@app/appointment/appointment-join/appointment-remote-link-graphql.service';
import { ConfigService } from '@app/core/config.service';
import { PageState } from '@app/pipes/page-state/page-state';
import { AppStoreRedirectService } from '@app/shared';

import { AppointmentAnalyticsService } from '../appointment-analytics.service';
import { AppointmentRemoteLink } from './__generated__/appointment-remote-link-graphql.service.types';

@Component({
  selector: 'om-appointment-join',
  templateUrl: './appointment-join.component.html',
  styleUrls: ['./appointment-join.component.scss'],
})
export class AppointmentJoinComponent implements OnInit, OnDestroy {
  appStoreUrl: string;
  zoomUrl: string;
  pageState: PageState = PageState.LOADING;

  private destroy$ = new Subject<void>();

  constructor(
    public configService: ConfigService,
    private appStoreRedirectService: AppStoreRedirectService,
    private appointmentRemoteLinkGraphqlService: AppointmentRemoteLinkGraphqlService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private analyticsService: AppointmentAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Join Visit');

    const appointmentId = this.activatedRoute.snapshot.params.id;

    this.appointmentRemoteLinkGraphqlService
      .watch({ id: appointmentId })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<AppointmentRemoteLink>) => {
          const link = result.data.appointment?.remoteMeetingLink;
          if (!link) {
            throw new Error('Appointment could not be found.');
          }

          return link;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (link: string) => {
          this.pageState = PageState.SUCCESS;
          this.zoomUrl = link;
          this.trackPageView(appointmentId, true);
        },
        error: () => {
          this.pageState = PageState.ERROR;
          this.trackPageView(appointmentId, false);
        },
      });

    this.appStoreUrl = this.appStoreRedirectService.redirectUrl;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private trackPageView(appointmentId: string, success: boolean) {
    this.analyticsService.appointmentJoinPageViewed({
      appointment_id: appointmentId,
      module_variant: success ? 'success' : 'error',
    });
  }
}
