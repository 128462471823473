import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { interval, Observable, of as observableOf, ReplaySubject, zip } from 'rxjs';
import { concatMap, debounce, map, switchMap, take } from 'rxjs/operators';

import { ChannelRoutingGraphQL } from '@app/appointment/channel-routing-graphql.service';
import { ApiService } from '@app/core/api.service';
import { AppointmentAnalyticsBaseService } from '@app/core/appointment-analytics-base.service';
import { MarkdownService } from '@app/core/markdown.service';
import { UserService } from '@app/core/user.service';
import { ContentBlockService } from '@app/shared';
import { Office } from '@app/shared/office';
import { Provider } from '@app/shared/provider';

import { AppointmentAnalyticsService } from '../appointment-analytics.service';
import { AppointmentType } from '../appointment-type';
import { AppointmentService } from '../appointment.service';
import { FromAppointment } from '../from-appointment';
import { AppointmentInventory } from '../provider-inventories';
import { AppointmentBookingState, AppointmentBookingType } from './appointment-booking-state';

interface AppointmentForRescheduling {
  id: string;
  startAt: string;
  timezoneTzinfo: string;
  office: {
    name: string;
  };
  provider: {
    displayName: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentBookingStateService {
  contentForSelectAppointmentType$: ReplaySubject<string | null>;

  private appointmentBookingState: AppointmentBookingState;

  constructor(
    private apiService: ApiService,
    private appointmentAnalyticsService: AppointmentAnalyticsService,
    private appointmentService: AppointmentService,
    private channelRoutingGraphQL: ChannelRoutingGraphQL,
    private markdownService: MarkdownService,
    private router: Router,
    private userService: UserService,
    private contentBlockService: ContentBlockService,
  ) {
    this.resetAppointmentBookingState();
    this.updateBookingStateOnUserServiceAreaChange();
  }

  setAppointmentBookingState(appointmentBookingState: AppointmentBookingState) {
    this.appointmentBookingState = appointmentBookingState;

    this.userService.user$.pipe(take(1)).subscribe(user => {
      if (!this.appointmentBookingState.serviceAreaSet()) {
        this.appointmentBookingState.setSelectedServiceArea(user.serviceArea);
      }
    });

    this.appointmentAnalyticsService.setBookingState(appointmentBookingState);
    this.initContentForSelectAppointmentType();
  }

  getAppointmentBookingState() {
    return this.appointmentBookingState;
  }

  resetAppointmentBookingState() {
    this.setAppointmentBookingState(new AppointmentBookingState());
  }

  setIsRecommendedRemoteAppointment(isRecommendedRemoteAppointment: boolean) {
    this.appointmentBookingState.setIsRecommendedRemoteAppointment(isRecommendedRemoteAppointment);
  }

  initContentForSelectAppointmentType() {
    const content$ = this.appointmentBookingState.selectedServiceArea$.pipe(
      debounce(() => interval(200)),
      concatMap(serviceArea => zip(observableOf(serviceArea))),
      concatMap(([serviceArea]) => {
        const serviceAreaId = serviceArea ? serviceArea.id : null;
        return this.contentBlockService
          .getContentBlock('appointment-type', 'default', 'appointment-type-select-header-web', serviceAreaId)
          .pipe(map((resp: any) => (resp.html ? this.markdownService.convertWithHeadings(resp.html) : null)));
      }),
    );
    const contentSubject$: ReplaySubject<string | null> = new ReplaySubject(1);
    content$.subscribe(contentSubject$);
    this.contentForSelectAppointmentType$ = contentSubject$;
  }

  contentForSearchAppointmentType(): Observable<SafeHtml> {
    return this.appointmentBookingState.selectedServiceArea$.pipe(
      take(1),
      switchMap(serviceArea => {
        const serviceAreaId = serviceArea ? serviceArea.id : null;
        return this.contentBlockService
          .getContentBlock(
            'appointment-type',
            this.appointmentBookingState.appointmentType.id,
            'appointment-type-search-content',
            serviceAreaId,
          )
          .pipe(map((resp: any) => (resp.html ? this.markdownService.convertWithHeadings(resp.html) : null)));
      }),
    );
  }

  setStateForRescheduling(appointment: AppointmentForRescheduling) {
    this.appointmentBookingState.bookingType = AppointmentBookingType.Reschedule;
    const fromAppointment = new FromAppointment();
    fromAppointment.id = parseInt(appointment.id, 10);
    fromAppointment.officeName = appointment.office.name;
    fromAppointment.providerName = appointment.provider.displayName;
    fromAppointment.startTime = appointment.startAt;
    fromAppointment.displayTimezone = appointment.timezoneTzinfo;
    this.appointmentBookingState.setFromAppointment(fromAppointment);
  }

  setStateForReviewBooking(
    selectedInventory: AppointmentInventory | undefined | null,
    inventoryProvider: Provider,
    office: Office,
    analyticsService: AppointmentAnalyticsBaseService,
    locationAttestation: string | undefined | null,
    afterHoursBillingDisclosure?: boolean,
    isAppointmentSeries = false,
  ) {
    this.appointmentBookingState.setAttributesForReviewBooking(
      selectedInventory,
      inventoryProvider,
      office,
      analyticsService,
      locationAttestation,
      afterHoursBillingDisclosure,
      isAppointmentSeries,
    );
  }

  updateAppointmentType(appointmentType: AppointmentType) {
    this.appointmentBookingState.setSelectedAppointmentType(appointmentType);
  }

  updateForSwitchToRemote(reason: string, flow: string, source: string) {
    this.appointmentBookingState.bookingType = AppointmentBookingType.SwitchToRemote;
    this.appointmentBookingState.reason = reason;
    this.appointmentBookingState.flow = flow;
    this.appointmentBookingState.source = source;
  }

  private updateBookingStateOnUserServiceAreaChange() {
    this.userService.user$.subscribe(user => {
      this.appointmentBookingState.setSelectedServiceArea(user.serviceArea);
    });
  }
}
