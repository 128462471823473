<div ngbDropdown>
  <button type="button" [class]="getButtonClasses()" id="addToCalendar" ngbDropdownToggle>
    <om-calendar *ngIf="!customButtonClass"></om-calendar>
    <span>Add to Calendar</span>
  </button>
  <div ngbDropdownMenu aria-labelledby="addToCalendar">
    <a
      ngbDropdownItem
      class="dropdown-item"
      (click)="handleAnalyticsEvent()"
      [href]="googleCalendarUrl"
      target="_blank"
      rel="noopener noreferrer"
      data-cy="google-calendar-link"
    >
      Google Calendar
    </a>
    <a
      ngbDropdownItem
      class="dropdown-item"
      (click)="handleAnalyticsEvent()"
      [href]="yahooCalendarUrl"
      target="_blank"
      rel="noopener noreferrer"
      data-cy="yahoo-calendar-link"
    >
      Yahoo Calendar
    </a>
    <a
      ngbDropdownItem
      class="dropdown-item"
      (click)="handleAnalyticsEvent()"
      [href]="outlookComCalendarUrl"
      target="_blank"
      rel="noopener noreferrer"
      data-cy="outlook-com-calendar-link"
    >
      Outlook.com Calendar
    </a>
    <a ngbDropdownItem class="dropdown-item" (click)="handleAnalyticsEvent()" [href]="icsFile">Outlook Calendar</a>
    <a ngbDropdownItem class="dropdown-item" (click)="handleAnalyticsEvent()" [href]="icsFile">Apple Calendar</a>
  </div>
</div>
