import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { Params, RouterModule } from '@angular/router';

import { appendQueryParamsToPath } from '@app/utils/link.utils';
import { TrackLinkFunction, TrackLinkModule } from '@app/utils/track-link.directive';
import { OmguiButtonModule, OmguiButtonSize, OmguiButtonVariant } from '@omgui/omgui-button/omgui-button.component';

export enum BootstrapTextColorClass {
  Muted = 'text-muted',
  Danger = 'text-danger',
}

export interface OmguiListCardItem {
  label: string;
  secondary?: boolean;
  sublabel?: string;
  sublabelClass?: BootstrapTextColorClass;
  sublabelTemplate?: TemplateRef<any>;
  action?: OmguiListCardItemAction;
}

export interface OmguiListCardItemAction {
  label: string;
  route?: string;
  href?: string;
  queryParams?: Params;
  trackingFunction?: TrackLinkFunction;
}

@Directive({
  selector: '[omgui-list-card-item-icon]',
  standalone: true,
})
export class OmguiListCardItemIconDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'omgui-list-card-item',
  standalone: true,
})
export class OmguiListCardItemDirective implements OnInit {
  @ContentChild(OmguiListCardItemIconDirective) icon: OmguiListCardItemIconDirective;

  /** The item to be rendered */
  @Input() data: OmguiListCardItem;
  @Output() buttonClick = new EventEmitter();

  path: string;

  ngOnInit(): void {
    if (!this.data.action) {
      return;
    }

    const { route, href, queryParams } = this.data.action;
    this.path = appendQueryParamsToPath(route || href, queryParams);
  }
}

export enum OmguiListCardVariants {
  /** Responsive list cards that have a button on larger breakpoints and become clickable instead at smaller breakpoints */
  Responsive = 'Responsive',
  /** Responsive list cards that have a button on all breakpoints and the entire card becomes clickable at smaller breakpoints */
  ResponsiveWithPersistentButton = 'ResponsiveWithPersistentButton',
  /** Compact list cards that are clickable at all breakpoints */
  Compact = 'Compact',
}

@Component({
  selector: 'omgui-list-card',
  templateUrl: './omgui-list-card.component.html',
  styleUrls: ['./omgui-list-card.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, TrackLinkModule, OmguiButtonModule],
})
export class OmguiListCardComponent {
  @ContentChildren(OmguiListCardItemDirective) items: QueryList<OmguiListCardItemDirective>;

  /** The title of the list of items */
  @Input() title: string;

  /** Whether or not the border should be present */
  @Input() borderless = false;

  /** The variant of the list card component */
  @Input() variant = OmguiListCardVariants.Responsive;

  readonly listCardVariants = OmguiListCardVariants;
  readonly bootstrapClass = BootstrapTextColorClass;
  readonly OmguiButtonSize = OmguiButtonSize;
  readonly OmguiButtonVariant = OmguiButtonVariant;
}

export const OMGUI_LIST_CARD_DIRECTIVES = [
  OmguiListCardComponent,
  OmguiListCardItemDirective,
  OmguiListCardItemIconDirective,
] as const;
