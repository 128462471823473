import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import {
  AppointmentRemoteLink,
  AppointmentRemoteLinkVariables,
} from './__generated__/appointment-remote-link-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class AppointmentRemoteLinkGraphqlService extends Query<AppointmentRemoteLink, AppointmentRemoteLinkVariables> {
  document = gql`
    query AppointmentRemoteLink($id: ID!) {
      appointment(id: $id) {
        id
        remoteMeetingLink
      }
    }
  `;
}
