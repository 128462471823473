import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of as observableOf } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';

import { PrimaryInsuranceAndMembershipIsDpcGraphQLService } from '@app/appointment/primary-insurance-and-membership-is-dpc-graphql.service';
import { FeatureFlags, FeatureFlagVariantsWithOn } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { UserService } from '@app/core/user.service';

import { User } from '../user';

export const InsuranceStatus = {
  SELF_PAY: 'Self Pay',
  PENDING: 'Pending',
  VERIFIED: 'Verified',
} as const;
export type InsuranceStatus = typeof InsuranceStatus[keyof typeof InsuranceStatus];

export interface InsuranceCaptureValues {
  variant: FeatureFlagVariantsWithOn;
  primaryInsuranceStatus: string | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class InsuranceCaptureService {
  user$: Observable<User>;

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private primaryInsuranceAndMembershipIsDpcGraphQLService: PrimaryInsuranceAndMembershipIsDpcGraphQLService,
    private userService: UserService,
  ) {
    this.user$ = this.userService.getUser();
  }

  updateLdUserAndGetInsuranceCaptureFlagEnabled$(isBillableVisit: boolean): Observable<boolean> {
    return this.updateLdUserAndGetInsuranceCaptureValues$(isBillableVisit).pipe(
      map(
        result =>
          result.variant === FeatureFlagVariantsWithOn.ON_VARIANT || result.variant === FeatureFlagVariantsWithOn.ON,
      ),
    );
  }

  updateLdUserAndGetInsuranceCaptureValues$(isBillableVisit: boolean): Observable<InsuranceCaptureValues> {
    if (!isBillableVisit) {
      return observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus: undefined });
    }

    return combineLatest([this.user$, this.primaryInsuranceAndMembershipIsDpcGraphQLService.fetch()]).pipe(
      switchMap(([user, graphqlResult]) => {
        const primaryInsuranceStatus = graphqlResult.data.patient?.primaryInsurance.verificationStatus;
        const isDpc = graphqlResult.data.membership?.isDpc ?? false;

        if (isDpc) {
          return observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus });
        }

        return from(
          this.launchDarklyService.updateUser({
            user,
            customAttributes: { primaryInsuranceStatus },
          }),
        ).pipe(
          take(1),
          switchMap(() => this.insuranceCaptureFlagVariant$()),
          map(variant => ({ variant, primaryInsuranceStatus })),
        );
      }),
      catchError(() => observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus: undefined })),
    );
  }

  private insuranceCaptureFlagVariant$(): Observable<FeatureFlagVariantsWithOn> {
    return this.launchDarklyService.featureFlag$<FeatureFlagVariantsWithOn>(
      FeatureFlags.INSURANCE_CAPTURE_WEB,
      FeatureFlagVariantsWithOn.OFF,
    );
  }
}
