<div>
  <button
    class="btn btn-block btn-primary {{ buttonClass }} {{ ariaDisabled ? 'disabled' : '' }}"
    [hidden]="!showSpinnerInsideButton && submitting"
    [disabled]="disabled"
    [attr.aria-disabled]="ariaDisabled"
    [attr.aria-label]="label ? label : 'submit'"
    type="submit"
    data-cy="submit"
  >
    <omgui-spinner
      *ngIf="showSpinnerInsideButton"
      class="me-2"
      [hidden]="!submitting"
      [size]="componentSize.extraExtraSmall"
      [useContrastingColor]="true"
      data-cy="spinner-inside-button"
    ></omgui-spinner>
    {{ label }}
  </button>
  <omgui-spinner
    *ngIf="!showSpinnerInsideButton"
    class="d-block text-center {{ buttonClass }}"
    [hidden]="!submitting"
    [size]="componentSize.extraSmall"
    data-cy="spinner-outside-button"
  ></omgui-spinner>
</div>
