import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FeatureFlags, FeatureFlagVariants } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { GuestGuardService } from '@app/core/guest-guard.service';

@Injectable()
export class EnhancedFunctionalityForLimitedAccessUsersGuardService implements CanActivate {
  constructor(private guestGuardService: GuestGuardService, private launchDarklyService: LaunchDarklyService) {}

  canActivate(): Observable<boolean> {
    return this.launchDarklyService
      .featureFlag$<FeatureFlagVariants>(
        FeatureFlags.ENHANCED_FUNCTIONALITY_FOR_LIMITED_ACCESS_USERS,
        FeatureFlagVariants.OFF,
      )
      .pipe(
        map(flag => flag === FeatureFlagVariants.ON_VARIANT),
        switchMap(enabled => {
          if (!enabled) {
            return this.guestGuardService.canActivate();
          }

          return observableOf(true);
        }),
      );
  }
}
