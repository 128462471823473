import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LetModule } from '@rx-angular/template/let';

import { AccountActiveGuardService } from '@app/core/account-active-guard.service';
import { DocumentSignerGraphQLService } from '@app/core/document-signer-graphql.service';
import { EnhancedFunctionalityForLimitedAccessUsersGuardService } from '@app/core/enhanced-functionality-for-limited-access-users-guard.service';
import { SendForgotPasswordEmailGraphQL } from '@app/core/forgot-password/send-forgot-password-email-graphql.service';
import { GuestGuardService } from '@app/core/guest-guard.service';
import { HomeScreenClaimCodeRoutingGuardService } from '@app/core/home-screen-claim-code-routing-guard.service';
import { LegalDocumentsModule } from '@app/core/legal-documents';
import { LoginGuardService } from '@app/core/login-guard.service';
import { MembershipService } from '@app/core/membership.service';
import { NavigatorService } from '@app/core/navigator.service';
import { UpdateCreditCardService } from '@app/core/update-credit-card.service';
import { PatientPreferencesService } from '@app/membership/patient-preferences.service';
import { NavbarHeaderComponent } from '@app/shared/navbar-header/navbar-header.component';
import { OmguiActionModule } from '@omgui/omgui-action/omgui-action.component';
import { OmguiBannerModule } from '@omgui/omgui-banner/omgui-banner.component';
import { OmguiModule } from '@omgui/omgui.module';

import { AppointmentService } from '../appointment/appointment.service';
import { SharedModule } from '../shared/shared.module';
import {
  MembershipB2bRenewGuardService,
  MembershipConsumerRenewGuardService,
  MembershipOMNowRenewGuardService,
  MembershipRenewGuardService,
} from './../membership/membership-renew-guard.service';
import { AnalyticsService } from './analytics.service';
import { ApiService } from './api.service';
import { AttemptedPathService } from './attempted-path.service';
import { AwsService } from './aws.service';
import { DateService } from './date.service';
import { DesignComponent } from './design/design.component';
import { DevRouteGuardService } from './dev-route-guard.service';
import { FeatureFlagGuard } from './feature-flag.guard';
import { EmailSentConfirmation } from './forgot-password/email-sent/email-sent-confirmation.component';
import { ForgotPassword } from './forgot-password/forgot-password.component';
import { MaintenanceModeGuardService } from './maintenance-mode-guard.service';
import { MarkdownService } from './markdown.service';
import { NavbarService } from './navbar.service';
import { ProviderService } from './provider.service';
import { RegistrationCompleteGuardService } from './registration-complete-guard.service';
import { StorageService } from './storage.service';
import { TargetUserService } from './target-user.service';
import { TosInterstitialComponent } from './tos/tos-interstitial.component';
import { UserAlertService } from './user-alert.service';
import { UserService } from './user.service';

@NgModule({
  declarations: [ForgotPassword, EmailSentConfirmation, TosInterstitialComponent, DesignComponent],
  imports: [
    CommonModule,
    FormsModule,
    LegalDocumentsModule,
    LetModule,
    OmguiActionModule,
    OmguiBannerModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    OmguiModule,
    NavbarHeaderComponent,
  ],
  providers: [
    AccountActiveGuardService,
    AnalyticsService,
    ApiService,
    AppointmentService,
    AttemptedPathService,
    AwsService,
    DateService,
    DevRouteGuardService,
    DocumentSignerGraphQLService,
    FeatureFlagGuard,
    GuestGuardService,
    LoginGuardService,
    MaintenanceModeGuardService,
    MarkdownService,
    MembershipB2bRenewGuardService,
    MembershipConsumerRenewGuardService,
    HomeScreenClaimCodeRoutingGuardService,
    MembershipOMNowRenewGuardService,
    MembershipRenewGuardService,
    MembershipService,
    NavbarService,
    NavigatorService,
    PatientPreferencesService,
    ProviderService,
    RegistrationCompleteGuardService,
    SendForgotPasswordEmailGraphQL,
    StorageService,
    TargetUserService,
    UpdateCreditCardService,
    UserAlertService,
    UserService,
    EnhancedFunctionalityForLimitedAccessUsersGuardService,
  ],
  exports: [],
})
export class CoreModule {}
