<ng-container *ngIf="isAppointmentConfirmationFullPageEnabled; else default">
  <div class="d-flex justify-content-between flex-wrap actions">
    <button
      type="button"
      class="confirm-appointment-button btn btn-primary rounded-pill"
      data-cy="appointment-confirmation"
      [disabled]="confirming"
      (click)="confirmAppointment()"
    >
      <span *ngIf="confirming; else confirmBlock">{{ confirmingCTA }}</span>
      <ng-template #confirmBlock>{{ confirmationCTA }}</ng-template>
    </button>
  </div>
</ng-container>

<ng-template #default>
  <div class="d-flex justify-content-between flex-wrap actions">
    <button
      type="button"
      class="modal-button btn btn-primary fw-light"
      data-cy="appointment-confirmation"
      [disabled]="confirming"
      (click)="confirmAppointment()"
    >
      <span *ngIf="confirming; else confirmBlock">Submitting...</span>
      <ng-template #confirmBlock>{{ confirmationCTA }}</ng-template>
    </button>
    <button
      type="button"
      class="modal-button btn btn-outline-primary fw-light"
      data-cy="appointment-cancel"
      [disabled]="confirming"
      (click)="modalClose()"
    >
      Cancel
    </button>
  </div>
</ng-template>
