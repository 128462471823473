<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="externalLink && !internalLink">
  <a
    #buttonRef
    [ngClass]="buttonClass"
    [class.disabled]="disabled"
    [href]="externalLink"
    [attr.target]="newTab ? '_blank' : null"
    rel="noopener noreferrer"
    [omTrackLink]="trackLink"
    data-cy="external-link"
    (click)="handler($event)"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>
<ng-container *ngIf="!externalLink && internalLink">
  <a
    #buttonRef
    [ngClass]="buttonClass"
    [class.disabled]="disabled"
    [routerLink]="internalLink"
    [target]="newTab ? '_blank' : null"
    [omTrackLink]="trackLink"
    [queryParams]="queryParams"
    data-cy="internal-link"
    (click)="handler($event)"
    [attr.role]="role"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>
<ng-container *ngIf="emitButtonClick && !semanticElementLink">
  <button
    #buttonRef
    [ngClass]="buttonClass"
    [disabled]="disabled"
    data-cy="action-button"
    [type]="type"
    (click)="handler($event)"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="emitButtonClick && semanticElementLink">
  <a
    #buttonRef
    [ngClass]="buttonClass"
    [class.disabled]="disabled"
    data-cy="action-link"
    [type]="type"
    (click)="handler($event)"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>
