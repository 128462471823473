import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  auth0: {
    audience: 'https://onemedical.com',
    clientId: 'FtUnVYtO4lpwjCydYWtUfxvlYntLSzF8',
    domain: 'sandbox-onemedical.us.auth0.com',
  },
  apiServer: 'https://seattle.app.1life.com',
  myoneServer: 'https://seattle.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-seattle',
  },
  launchDarklyClientId: '605035fe824e970bccf18bfb',
};
