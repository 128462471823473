import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfigService } from '@app/core/config.service';
import { LinksService } from '@app/core/links.service';
import {
  AUTH0_LOGIN_PAGE,
  LOG_IN_WITH_EXISTING_ACCOUNT_SUBMITTED,
  LOGIN_WITH_AMAZON_MP_FLOW_VERSION,
  PREPAID_REGISTER_MP_FLOW_VERSION,
  SIGN_UP_MANUALLY_SUBMITTED,
  SIGN_UP_TAPPED,
  VISIT_AMAZON_CLICKED,
} from '@app/core/mixpanel.constants';
import { OmguiButtonModule, OmguiButtonVariant } from '@app/omgui/omgui-button/omgui-button.component';
import { OmguiExternalLinkIconModule } from '@app/omgui/omgui-external-link-icon/omgui-external-link-icon.component';
import { ERROR_STATE_DIRECTIVES } from '@app/shared/error-state/error-state.component';
import { CLAIM_CODE_PARAM_KEY, HORNBILL_ANALYTICS_FLOW_VERSION_PARAM_KEY } from '@app/shared/hornbill-params';
import { SimpleNavbarComponent } from '@app/shared/simple-navbar/simple-navbar.component';
import { WindowService } from '@app/utils/window.service';

import { RegistrationAnalyticsService } from '..';

export enum RegistrationErrorCodes {
  PrepaidEnrollmentDefault = 'prepaid_enrollment_default',
  PrepaidEnrollmentInvalidCode = 'prepaid_enrollment_invalid_code',
  PrepaidEnrollmentCodeClaimed = 'prepaid_enrollment_claimed_code',
  LoginWithAmazonEnrollmentDefault = 'login_with_amazon_enrollment_default',
  DuplicateExternalId = 'duplicate_external_id',
  LoggingInWithAmazonWithoutLinkedPatientAccount = 'logging_in_with_amazon_without_linked_patient_account',
}

export enum MembershipGroupInviteValidationErrors {
  NotFound = 'not_found',
  InvalidCode = 'invalid_code',
  RevokedCode = 'revoked_code',
  RejectedCode = 'rejected_code',
  ClaimedCode = 'claimed_code',
}

interface RegistrationErrorActionSelectedArgs {
  action: string;
  destinationLink?: string;
}

@Component({
  selector: 'om-registration-error',
  templateUrl: './registration-error.component.html',
  styleUrls: ['./registration-error.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SimpleNavbarComponent,
    OmguiButtonModule,
    OmguiExternalLinkIconModule,
    ...ERROR_STATE_DIRECTIVES,
  ],
})
export class RegistrationErrorComponent implements OnInit {
  errorCode: RegistrationErrorCodes = RegistrationErrorCodes.PrepaidEnrollmentDefault;
  claimCode?: string;
  routeSource?: string;

  protected manageMembershipOnAmazonUrl: string;
  protected securityDeepLinkUrl: string;

  protected readonly VISIT_AMAZON_CLICKED: string = VISIT_AMAZON_CLICKED;
  protected readonly SIGN_UP_MANUALLY_SUBMITTED: string = SIGN_UP_MANUALLY_SUBMITTED;
  protected readonly LOG_IN_WITH_EXISTING_ACCOUNT_SUBMITTED: string = LOG_IN_WITH_EXISTING_ACCOUNT_SUBMITTED;
  protected readonly SIGN_UP_TAPPED: string = SIGN_UP_TAPPED;

  readonly RegistrationErrorCodes = RegistrationErrorCodes;
  readonly ButtonVariant = OmguiButtonVariant;
  readonly AUTH0_LOGIN_PAGE = AUTH0_LOGIN_PAGE;

  constructor(
    readonly links: LinksService,
    private activatedRoute: ActivatedRoute,
    private registrationAnalyticsService: RegistrationAnalyticsService,
    private config: ConfigService,
    private windowService: WindowService,
  ) {}

  ngOnInit() {
    this.manageMembershipOnAmazonUrl = this.config.json.amazonUrls.manageMembership;

    const routeErrorCode = this.activatedRoute.snapshot.queryParams['errorCode'];
    const routeClaimCode = this.activatedRoute.snapshot.queryParams[CLAIM_CODE_PARAM_KEY];
    const registrationErrorCodes = [
      RegistrationErrorCodes.PrepaidEnrollmentInvalidCode,
      RegistrationErrorCodes.PrepaidEnrollmentCodeClaimed,
      RegistrationErrorCodes.LoginWithAmazonEnrollmentDefault,
      RegistrationErrorCodes.DuplicateExternalId,
      RegistrationErrorCodes.LoggingInWithAmazonWithoutLinkedPatientAccount,
    ];

    if (registrationErrorCodes.includes(routeErrorCode)) {
      this.errorCode = routeErrorCode;
      this.claimCode = routeClaimCode;
    }

    this.routeSource = this.activatedRoute.snapshot.queryParams['errorSource'];

    this.trackPageViewed();

    this.securityDeepLinkUrl = this.windowService.getLocationOrigin() + this.links.security;
  }

  trackActionSelected(args: RegistrationErrorActionSelectedArgs): Function {
    switch (this.errorCode) {
      case RegistrationErrorCodes.DuplicateExternalId:
      case RegistrationErrorCodes.LoggingInWithAmazonWithoutLinkedPatientAccount:
        return this.registrationAnalyticsService.trackLoginErrorActionSelected.bind(
          this.registrationAnalyticsService,
          args.action,
          this.errorCode,
        );
      default:
        return this.registrationAnalyticsService.registrationErrorPageActionSelected.bind(
          this.registrationAnalyticsService,
          args.action,
          this.errorCode,
          this.flowVersion,
          args.destinationLink,
        );
    }
  }

  private trackPageViewed() {
    switch (this.errorCode) {
      case RegistrationErrorCodes.DuplicateExternalId:
      case RegistrationErrorCodes.LoggingInWithAmazonWithoutLinkedPatientAccount:
        this.registrationAnalyticsService.trackLoginBeforeSwitchErrorPageViewed(this.errorCode);
        break;
      default:
        this.registrationAnalyticsService.trackPrepaidEnrollmentErrorPageViewed(
          this.errorCode,
          this.claimCode,
          this.routeSource,
          this.flowVersion,
        );
    }
  }

  private get flowVersion(): string {
    const routeFlowVersion = this.activatedRoute.snapshot.queryParams[HORNBILL_ANALYTICS_FLOW_VERSION_PARAM_KEY];
    if (!!routeFlowVersion) {
      return routeFlowVersion;
    } else if (
      this.errorCode === RegistrationErrorCodes.LoginWithAmazonEnrollmentDefault ||
      this.errorCode === RegistrationErrorCodes.LoggingInWithAmazonWithoutLinkedPatientAccount
    ) {
      // amazon redirect won't specify a flow version
      return LOGIN_WITH_AMAZON_MP_FLOW_VERSION;
    } else {
      return PREPAID_REGISTER_MP_FLOW_VERSION;
    }
  }
}
