import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import {
  AppointmentDetails,
  AppointmentDetailsVariables,
} from './__generated__/appointment-details-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class AppointmentDetailsGraphQLService extends Query<AppointmentDetails, AppointmentDetailsVariables> {
  document = gql`
    query AppointmentDetails($id: ID!) {
      appointment(id: $id) {
        id
        startAt
        reason
        state
        timezoneTzinfo
        isDifferentOnsiteTimezone
        joinLink
        appointmentType {
          id
          displayedDuration
          displayName
          isBillable
          isSpecialty
          remote
        }
        office {
          id
          serviceArea {
            id
            code
            name
            registrationHidden
            virtual
            defaultOffice {
              id
            }
          }
        }
        provider {
          id
          displayName
          profileImageUrl
        }
      }
    }
  `;
}
