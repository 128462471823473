import { Component, OnInit } from '@angular/core';

import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';
import { FLOW_VERSION_GCN_POST_VISIT } from '@app/core/mixpanel.constants';
import { GraphNavigationService } from '@app/registration/graph/graph-navigation.service';

import { NavbarHeaderComponent } from '../../shared/navbar-header/navbar-header.component';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'om-trial-video-visit-onboarding',
  templateUrl: './trial-video-visit-onboarding.component.html',
  standalone: true,
  imports: [NavbarHeaderComponent, SharedModule],
})
export class TrialVideoVisitOnboardingComponenent implements OnInit {
  constructor(private graphNavigationService: GraphNavigationService, private membershipService: MembershipService) {}

  private mapToGraphMembershipInfo(membership: Membership) {
    return {
      membershipType: 'Consumer Trial',
      subscriptionId: membership?.groupSubscriptionDetails?.id?.toString(),
      billingCycle: membership?.groupSubscriptionDetails?.billingCycle,
      membershipSubscriptionType: membership?.getGroupSubscriptionType(),
      membershipStatus: 'Current',
    };
  }

  ngOnInit(): void {
    this.membershipService.getMembership().subscribe(membershipInfo => {
      const graphMembershipInfo = this.mapToGraphMembershipInfo(membershipInfo);

      this.graphNavigationService.navigate({
        flowVersion: FLOW_VERSION_GCN_POST_VISIT,
        membershipInfo: graphMembershipInfo,
      });
    });
  }
}
