import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import {
  PrimaryInsuranceAndMembershipIsDpc,
  PrimaryInsuranceAndMembershipIsDpcVariables,
} from './__generated__/primary-insurance-and-membership-is-dpc-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class PrimaryInsuranceAndMembershipIsDpcGraphQLService extends Query<
  PrimaryInsuranceAndMembershipIsDpc,
  PrimaryInsuranceAndMembershipIsDpcVariables
> {
  document = gql`
    query PrimaryInsuranceAndMembershipIsDpc {
      patient {
        id
        primaryInsurance {
          id
          verificationStatus
        }
      }
      membership {
        id
        isDpc
      }
    }
  `;
}
