import { Injectable } from '@angular/core';
import S3 from 'aws-sdk/clients/s3';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { DocumentItem } from '@app/shared/document-item';

import { User } from '../shared/user';
import { AnalyticsService } from './analytics.service';
import { ApiService } from './api.service';
import { FeatureFlags } from './feature-flags/feature-flags';
import { LaunchDarklyService } from './feature-flags/launchdarkly.service';
import { UploadData } from './upload-data';
import { UserService } from './user.service';

export class ApiStatus {
  static NOT_READY = 'NOT_READY';
  static READY = 'READY';
}

@Injectable()
export class AwsService {
  private static LINK_EXPIRATION_TIME = 60;
  private s3: S3;
  private sessionExpiresAt: Date;
  private bucket: string;
  private preScanBucket?: string;
  private currentUser: User;
  private uploadPathRoot: string;
  apiStatusStream: BehaviorSubject<string>;
  viewStream: ReplaySubject<DocumentItem>;
  downloadStream: ReplaySubject<DocumentItem>;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private launchDarklyService: LaunchDarklyService,
  ) {
    this.apiStatusStream = new BehaviorSubject(ApiStatus.NOT_READY);
    this.viewStream = new ReplaySubject<DocumentItem>();
    this.downloadStream = new ReplaySubject<DocumentItem>();
    this.userService.user$.subscribe(user => {
      this.currentUser = user;
      this.getToken();
    });

    this.userService.getUser();

    this.initViewAction();
    this.initDownloadAction();
  }

  view(document: DocumentItem) {
    this.checkAndUpdateToken();
    this.viewStream.next(document);
  }

  download(document: DocumentItem) {
    this.checkAndUpdateToken();
    this.downloadStream.next(document);
  }

  private checkAndUpdateToken() {
    if (this.sessionExpiresAt && Date.now() >= this.sessionExpiresAt.getTime()) {
      this.apiStatusStream.next(ApiStatus.NOT_READY);
      this.getToken();
    }
  }

  private getToken() {
    this.apiService.post('/api/v2/aws_session', null).subscribe((response: any) => {
      this.sessionExpiresAt = new Date(response.expires_at);
      this.bucket = response.bucket;
      this.preScanBucket = response.pre_scan_bucket;
      this.uploadPathRoot = `patients/${this.currentUser.id}`;
      this.s3 = new S3({
        region: 'us-east-1',
        credentials: {
          accessKeyId: response.credentials.access_key_id,
          secretAccessKey: response.credentials.secret_access_key,
          sessionToken: response.credentials.session_token,
        },
        signatureVersion: 'v4',
      });
      this.apiStatusStream.next(ApiStatus.READY);
    });
  }

  private initViewAction() {
    combineLatest([this.viewStream, this.apiStatusStream])
      .pipe(filter(vals => vals[1] === ApiStatus.READY))
      .subscribe(vals => {
        const document: DocumentItem = vals[0];
        this.getSignedUrl(document, 'inline', (err: Error, url: string) => {
          if (url) {
            window.open(url);
          }
        });
        this.analyticsService.attachmentViewed(document);
      });
  }

  private initDownloadAction() {
    combineLatest([this.downloadStream, this.apiStatusStream])
      .pipe(filter(vals => vals[1] === ApiStatus.READY))
      .subscribe(vals => {
        const document: DocumentItem = vals[0];
        this.getSignedUrl(document, 'attachment', (err: Error, url: string) => {
          if (url) {
            window.location.href = url;
          }
        });
        this.analyticsService.attachmentDownloaded(document);
      });
  }

  private getSignedUrl(document: DocumentItem, contentDisposition: string, handler: (err: Error, url: string) => void) {
    this.s3.getSignedUrl(
      'getObject',
      {
        Bucket: document.bucket,
        Key: document.key,
        Expires: AwsService.LINK_EXPIRATION_TIME,
        ResponseContentDisposition: contentDisposition,
      },
      handler,
    );
  }

  upload(key: string, file: File): Subject<any> {
    this.checkAndUpdateToken();
    const uploadStream = new Subject();
    const bucketToUse = this.preScanBucket;

    this.apiStatusStream
      .pipe(
        filter(status => status === ApiStatus.READY),
        switchMap(() => this.removeNonAsciiCharacters$(key)),
      )
      .subscribe(fileKey => {
        const params = {
          Key: `${this.uploadPathRoot}/${fileKey}`,
          Body: file,
          Bucket: bucketToUse,
          ContentType: file.type,
          ServerSideEncryption: 'AES256',
        };

        const options = {};

        this.s3
          .upload(params, options, (error, data) => {
            if (error) {
              uploadStream.error(error);
            } else {
              uploadStream.next(new UploadData(true, 100, data.Key, this.bucket)); // set data bucket explicitly to account for BucketAV transfer
            }
          })
          .on('httpUploadProgress', event => {
            uploadStream.next(new UploadData(false, Math.round((100 * event.loaded) / event.total)));
          });
      });

    return uploadStream;
  }

  private removeNonAsciiCharacters$(key: string): Observable<string> {
    return this.launchDarklyService.featureFlag$(FeatureFlags.ENABLE_UPLOADED_FILE_NAME_SANITIZATION, false).pipe(
      map(enabled => {
        if (!enabled) {
          return key;
        }

        return key.replace(/[^\x00-\x7F]/g, '');
      }),
      take(1),
    );
  }
}
