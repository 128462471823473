import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SourceService {
  static readonly SOURCE_PARAM = 'source';
  static readonly UTM_SOURCE_PARAM = 'utm_source';
  static readonly UTM_MEDIUM_PARAM = 'utm_medium';

  constructor(private activatedRoute: ActivatedRoute) {}

  get source(): string | undefined {
    return this.activatedRoute.snapshot.queryParams[SourceService.SOURCE_PARAM];
  }

  get utmSource(): string | undefined {
    return this.activatedRoute.snapshot.queryParams[SourceService.UTM_SOURCE_PARAM];
  }

  get utmSourceWithUtmMedium(): string | undefined {
    const utmSource = this.utmSource;
    const utmMedium = this.activatedRoute.snapshot.queryParams[SourceService.UTM_MEDIUM_PARAM];

    if (utmSource) {
      return [utmSource, utmMedium].filter(Boolean).join('_');
    }
  }
}
